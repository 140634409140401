/* globals VXConfig */
import React                         from "react";
import PropTypes                     from 'prop-types';
import Constants, { getTypeByColor } from './Constants';
import Translations                  from '../../utils/Translations';
import Flux                          from '../../flux/Flux';
import {formatCurrency}              from "../../utils/CommonUtils";
import Alert, { BUTTON }             from "../Alert/Alert.js";
import BARCOLORS                     from "./Constants";

const MODE_ACTIVATE = 'activate';
const MODE_LATER    = 'later';

let selectedMode;

class StatusBarAutomaticRecharge extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error:        false,
            isLoading:    false,
            isVisible:    true,
            gotActivated: false,
        };

        this.onActivateClick                 = this.onActivateClick.bind(this);
        this.onAutoRechargeTeaserStateChange = this.onAutoRechargeTeaserStateChange.bind(this);
        this.onCanOneClickChange             = this.onCanOneClickChange.bind(this);
        this.onCloseClick                    = this.onCloseClick.bind(this);
        this.onLaterClick                    = this.onLaterClick.bind(this);
    }

    componentDidMount() {
        Flux.Guest.addAutoRechargeTeaserStateChangeListener(this.onAutoRechargeTeaserStateChange);
        Flux.Guest.addCanOneClickChangeListener(this.onCanOneClickChange);
    }

    componentWillUnmount() {
        Flux.Guest.removeAutoRechargeTeaserStateChangeListener(this.onAutoRechargeTeaserStateChange);
        Flux.Guest.removeCanOneClickChangeListener(this.onCanOneClickChange);
    }

    onActivateClick(e) {
        e.preventDefault();
        if (!this.state.isLoading) {
            this.setState({
                isLoading: true,
            });
            Flux.Guest.setAutoRechargeTeaserState(MODE_ACTIVATE);
            selectedMode = MODE_ACTIVATE;
        }
    }

    onAutoRechargeTeaserStateChange(success) {
        if (success && selectedMode !== MODE_ACTIVATE) {
            this.setState({
                isVisible: false,
            });
        } else if (!success) {
            this.setState({
                error:     true,
                isLoading: false,
            });
        }
    }

    onCanOneClickChange() {
        if (selectedMode === MODE_ACTIVATE) {
            if (Flux.Guest.canAutoRecharge()) {
                this.setState({
                    isLoading:    false,
                    gotActivated: true,
                });
            } else {
                this.setState({
                    error:     true,
                    isLoading: false,
                });
            }
        }
    }

    onCloseClick(e) {
        e.preventDefault();
        this.setState({
            isVisible: false,
        });
    }

    onLaterClick(e) {
        e.preventDefault();
        Flux.Guest.setAutoRechargeTeaserState(MODE_LATER);
        this.setState({
            isLoading: true,
        });
        selectedMode = MODE_LATER;
    }

    render() {
        if (!this.state.isVisible) {
            return null;
        }
        const msgIntro = this.props.msgIntro.replace(":guestName", Flux.Guest.getName());

        if (this.state.error || this.state.gotActivated) {
            const type = this.state.error ?  BARCOLORS.BARCOLOR_RED : BARCOLORS.BARCOLOR_GREEN;
            const text = this.state.error ? Translations.get('StatusBarAutomaticRechargeError') : Translations.get('StatusBarAutomaticRechargeSuccess').replace(':amount', formatCurrency(Flux.Guest.getAutoRechargeAmount() / 100));
            return <Alert
                type={getTypeByColor(type)}
                text={text}
                buttons={[{
                        type: BUTTON.SECONDARY,
                        text: Translations.get('BtnClose'),
                        onClick: this.onCloseClick
                }]}
            />;
        } else {
            return <Alert
                headline={msgIntro}
                text={this.props.msgOutro}
                type={getTypeByColor(this.props.barColor)}
                buttons={[
                    {type: BUTTON.SECONDARY, text: this.props.hideText, onClick: this.onLaterClick},
                    {type: BUTTON.PRIMARY, text: this.props.btnLabel, onClick: this.onActivateClick},
                ]}
            />;
        }
    }
}

StatusBarAutomaticRecharge.propTypes = {
    barColor:  PropTypes.string.isRequired,
    msgIntro:  PropTypes.string.isRequired,
    msgOutro:  PropTypes.string.isRequired,
    btnLabel:  PropTypes.string.isRequired,
    isVisible: PropTypes.bool,
    hideText:  PropTypes.string.isRequired,
};

StatusBarAutomaticRecharge.defaultProps = {
    barColor:  Constants.BARCOLOR_WHITE,
    isVisible: true,
};

export default StatusBarAutomaticRecharge;
